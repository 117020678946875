import React from 'react';

class SearchBar extends React.Component {
    render(){
        return (
            <div className="search-bar">
                <input type="text"placeholder="Type your search here..." />

            </div>
        )
    }
}

export default SearchBar;