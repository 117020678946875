export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links',
    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links',
    },
    {
        title: 'Favourites',
        url: '/favourites',
        cName: 'nav-links',
    },
    
    

]