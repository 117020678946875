import React from 'react';

class Footer extends React.Component {
    render() {
        return (
        <footer>
            <h3>Cinemall</h3>
            <p>Website Made by: Claire Koga, Hirdey Grewal, and Wei Liu</p>
            <p>&copy; TWD 25 2020</p>
        </footer>
        );
    }
}

export default Footer;